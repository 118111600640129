import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import store from '../store'

import Layout from '@/views/layout/index.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {
        path: '/login',
        component: () => import("../views/login/index.vue"),
        meta: {hidden: true}
    },
    {
        path: '/404',
        component: () => import("../views/404.vue"),
        meta: {hidden: true}
    },
    {
        path: '/',
        component: Layout,
        redirect: '/dashboard',
        meta: {},
        children: [{
          path: 'dashboard',
          name: 'Dashboard',
          component: () => import('@/views/dashboard/index.vue'),
          meta: { title: '总览', icon: 'el-icon-s-marketing' }
        }]
    },
    {
        path: '/user',
        component: Layout,
        meta: {},
        children: [{
          path: 'index',
          name: 'User',
          component: () => import('@/views/user/index.vue'),
          meta: { title: '用户管理', icon: 'el-icon-s-custom' }
        }]
    },
    {
        path: '/app',
        component: Layout,
        meta: {},
        children: [{
          path: 'index',
          name: 'App',
          component: () => import('@/views/app/index.vue'),
          meta: { title: '应用管理', icon: 'el-icon-coin' }
        }]
    },
    {
        path: '/bill',
        component: Layout,
        meta: {},
        children: [{
          path: 'index',
          name: 'Bill',
          component: () => import('@/views/bill/index.vue'),
          meta: { title: '账单', icon: 'el-icon-document' }
        }]
    },
     // 404 page must be placed at the end !!!
    { path: '*', redirect: '/404', meta: {hidden: true} }
]
const router = new VueRouter({
    base: process.env.BASE_URL,
    routes
})

export default router

