<template>
  <div :class="classObj" class="app-wrapper">
    <div v-if="device==='mobile'&&sidebar.opened" class="drawer-bg" @click="handleClickOutside" />
    <sidebar class="sidebar-container" />
    <div class="main-container">
      <div :class="{'fixed-header':fixedHeader}">
        <navbar />
      </div>
      <app-main />
    </div>
    <el-dialog
      title="修改密码"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <el-form ref="form" :model="form" :rules="rules" label-width="160px">
        <el-form-item label="旧密码：" prop="oldPwd">
          <el-input v-model="form.oldPwd" show-password placeholder="请输入旧密码" />
        </el-form-item>
        <el-form-item label="新密码：" prop="newPwd">
          <el-input v-model="form.newPwd" show-password placeholder="请输入新密码" />
        </el-form-item>
        <el-form-item label="确认密码：" prop="confirmPwd">
          <el-input v-model="form.confirmPwd" show-password placeholder="请确认密码" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button v-loading="btnLoading" @click="handleClose()">取 消</el-button>
        <el-button v-loading="btnLoading" type="primary" @click="handleSubmit()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { Navbar, Sidebar, AppMain } from './components'
import ResizeMixin from './mixin/ResizeHandler'
import { changePwd } from '@/service/apiService.ts'
export default {
  name: 'Layout',
  components: {
    Navbar,
    Sidebar,
    AppMain
  },
  mixins: [ResizeMixin],
  data() {
    const validateconfirmPwd = (rule, value, callback) => {
      if (value !== this.form.newPwd) {
        callback(new Error('两次输入的密码不同'))
      } else {
        callback()
      }
    }
    return {
      dialogVisible: false,
      btnLoading: false,
      form: {
        oldPwd: null,
        newPwd: null,
        confirmPwd: null
      },
      rules: {
        oldPwd: [
          { required: true, message: '请输入旧密码', trigger: 'blur' },
          {
            min: 6,
            max: 20,
            message: '长度在 6 到 20 个字符',
            trigger: 'blur'
          }
        ],
        newPwd: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          {
            min: 6,
            max: 20,
            message: '长度在 6 到 20 个字符',
            trigger: 'blur'
          }
        ],
        confirmPwd: [{ required: true, trigger: 'blur', validator: validateconfirmPwd }]
      }
    }
  },
  computed: {

    sidebar() {
      return this.$store.state.app.sidebar
    },
    device() {
      return this.$store.state.app.device
    },
    fixedHeader() {
      return this.$store.state.settings.fixedHeader
    },
    classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === 'mobile'
      }
    }
  },
  methods: {
    async handleSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.btnLoading = true
          changePwd(this.form.oldPwd, this.form.newPwd)
            .then((res) => {
              this.btnLoading = false
              this.$message({
                message: '修改密码成功',
                type: 'success'
              })
              this.handleClose()
            })
            .catch(() => (
              this.btnLoading = false
            ))
        }
      })
    },
    handleClickOutside() {
      this.$store.dispatch('app/closeSideBar', { withoutAnimation: false })
    },
    handleClose() {
      this.form = {
        oldPwd: null,
        newPwd: null,
        confirmpassroed: null
      }
      this.$refs.form.resetFields()
      this.dialogVisible = false
    },
    changepwd() {
      this.dialogVisible = true
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "~@/assets/css/mixin.scss";
  @import "~@/assets/css/variables.scss";

  .app-wrapper {
    @include clearfix;
    position: relative;
    height: 100%;
    width: 100%;
    &.mobile.openSidebar{
      position: fixed;
      top: 0;
    }
  }
  .drawer-bg {
    background: #000;
    opacity: 0.3;
    width: 100%;
    top: 0;
    height: 100%;
    position: absolute;
    z-index: 999;
  }

  .fixed-header {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 9;
    width: calc(100% - #{$sideBarWidth});
    transition: width 0.28s;
  }

  .hideSidebar .fixed-header {
    width: calc(100% - 54px)
  }

  .mobile .fixed-header {
    width: 100%;
  }
</style>
