import axios from "./config"
import { Result } from "../types"

/**
 * 登录
 * @param account 
 * @param password 
 * @param captcha
 * @param type
 */
export async function login(account: string, password: string,captcha: string,type: number = 1): Promise<Result> {
    var result = await axios.post('login',
        `account=${account}&password=${password}&captcha=${captcha}&type=${type}`
    )
    return result.data
}
/**
 * 修改密码
 * @param oldPwd 
 * @param newPwd 
 */
export async function changePwd(oldPwd: string, newPwd: string): Promise<Result> {
    var result = await axios.post('password',
        `oldPwd=${oldPwd}&newPwd=${newPwd}`
    )
    return result.data
}

export async function getInfo() {
    var result = await axios.post('userInfo')
    return result.data
}
export async function queryAgentStatistics(type:number, userId: number): Promise<Result>  {
    var result = await axios.post('agent/statistics',
        `type=${type}&userId=${userId}`
    )
    return result.data
} 

export async function queryUserList(keywords:string, page: number = 1,size: number = 20): Promise<Result>  {
    var result = await axios.post('agent/userList',
        `keywords=${keywords}&page=${page}&size=${size}`
    )
    return result.data
} 
export async function queryAgentInfo(): Promise<Result>  {
    var result = await axios.post('agent/info')
    return result.data
} 

export async function createUser(account: string, password: string): Promise<Result>  {
    var result = await axios.post('agent/addUser',
        `account=${account}&password=${password}`
    )
    return result.data
} 

export async function userRecharge(userId: number, count: number,remark: string): Promise<Result>  {
    var result = await axios.post('agent/userRecharge',
        `userId=${userId}&count=${count}&remark=${remark}`
    )
    return result.data
} 
export async function userDelete(userId: number): Promise<Result>  {
    var result = await axios.post('agent/userDelete',
        `userId=${userId}`
    )
    return result.data
} 

export async function queryAppList(userId: number,keywords:string, page: number = 1,size: number = 20): Promise<Result>  {
    var result = await axios.post('app/list',
        `userId=${userId}&keywords=${keywords}&page=${page}&size=${size}`
    )
    return result.data
}

export async function updateShelf(appId:number, shelf: boolean): Promise<Result>  {
    var result = await axios.post('app/shelf',
        `appId=${appId}&shelf=${shelf}`
    )
    return result.data
}

export async function updateMode(appId:number, mode: number): Promise<Result>  {
    var result = await axios.post('app/updateMode',
        `appId=${appId}&mode=${mode}`
    )
    return result.data
}

export async function queryRechargeLog(userId:number, page: number, size: number, start: string|null,end: string|null): Promise<Result>  {
    var parm = `userId=${userId}&page=${page}&size=${size}`
    if (start && end) {
        parm = parm + `&start=${start}&end=${end}`
    }
    var result = await axios.post('agent/rechargeLog', parm)
    return result.data
}
