import axios from "axios"
import { Message } from 'element-ui'
import store from '@/store'
import { getToken } from '@/utils/auth.js'

// axios 配置
axios.defaults.timeout = 50000
axios.defaults.baseURL = '/api'
axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded'//配置默认请求头

// http request 拦截器
axios.interceptors.request.use(
    request => {
        if (store.getters.token) {
            request.headers['Authorization'] = 'Bearer ' + getToken()
        }
        return request
    },
    err => {
        return Promise.reject(err)
    },
)

// http response 拦截器
axios.interceptors.response.use(
    response => {
        if (response.data.code == 401) {
            store.dispatch('logout').then(() => {
                Message({
                    message: response.data.message,
                    type: 'error',
                    duration: 1000,
                    onClose: () => location.reload()// 为了重新实例化vue-router对象 避免bug
                })
            });
            return Promise.reject(response.data.message);
        } else if (response.data.code !== 200) {
            Message({
                message: response.data.message || 'Error',
                type: 'error',
                duration: 5 * 1000
              })
            return Promise.reject(new Error(response.data.message || 'Error'))
        }
        return response
    },
    err => {
        Message({
            message: err.message,
            type: 'error',
            duration: 5 * 1000
        })
        return Promise.reject(err)
    },
)

export default axios